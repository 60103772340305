import React, { useContext, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

import ColorContext from 'mobilflower-ui/dist/common/src/components/Color/context'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import MenuItem from 'mobilflower-ui/dist/common/src/components/MenuItem'
import Typography from 'mobilflower-ui/dist/common/src/components/Typography'
import Image from 'mobilflower-ui/dist/common/src/components/Image'
import MenuContext from 'mobilflower-ui/dist/common/src/components/Menu/context'
import Menu from 'mobilflower-ui/dist/common/src/components/Menu'
import VerticalMoreIcon from 'mobilflower-ui/dist/common/src/svg/VerticalMoreIcon'

import AppContext from '../../App/context'
import Avatar from '../../Avatar'
import Table from '../../Table'

import { CustomCheckbox } from './CustomCheckbox'

import defaultStyle from '../style.css'

export const CustomMenuItem = React.forwardRef((props, ref) => {

    const appContext = useContext(AppContext)
    const colorContext = useContext(ColorContext)
    const context = useContext(WappContext)

    const { wapp } = context

    const {
        selectable,
        author,
        thumb,
        menu,
        menuContext = {},
        _classNames = defaultStyle,
        MenuItemComponent = MenuItem,
        MenuItemComponentRoot = MenuItemComponent,
        MenuItemComponentForCheckbox = MenuItemComponent,
        name = 'post',
        itemProps = {},
        disabled,
        preventReload,

        active,
        color = colorContext.color,
        size,
        effect,
        href,
        preventHref,
        checkboxRef,
        disableElevation,
        enableBackground,
        className,
        label,
        onClick,
        onClickBefore,
        EndIconContainerComponent,
        secondaryText,
        hideInactive,
        CheckboxComponent = CustomCheckbox,
        AvatarProps = {},
        index,
        ...rest2
    } = props

    let {
        onClickIsSelect,
        ...rest
    } = rest2

    if (!selectable) {
        onClickIsSelect = false
    }

    if (!props._classNames) {
        wapp.styles.use(_classNames)
    }

    const buttonAction = useRef({})
    const buttonRef = useRef()

    const [_active, setActive] = useState(active)

    useEffect(() => {
        if (effect) {
            effect({
                ...buttonAction.current,
                actions: {
                    ...buttonAction.current.actions ? buttonAction.current.actions : {},
                    setActive: async (value) => {
                        await setActive(value)
                    }
                }
            })
        }
        return () => {
            if (effect) {
                effect({
                    ...buttonAction.current,
                    actions: {
                        ...buttonAction.current.actions ? buttonAction.current.actions : {},
                        setActive: () => {
                        }
                    }
                })
            }
        }
    })

    useEffect(() => {
        if (active !== _active) {
            setActive(active)
        }
    }, [active])

    return (
        <MenuItemComponentRoot
            fullWidth={true}
            size={size}
            Component={'div'}
            disabled={onClickIsSelect && disabled ? disabled : false}
            enableBackground={enableBackground}
            disableElevation={disableElevation}
            className={clsx(
                _classNames.menuItem,
                { [className]: onClickIsSelect },
                { [_classNames.onClickIsSelect]: onClickIsSelect },
                { [_classNames.preventHref]: preventHref },
                { [_classNames.active]: _active },
                { [_classNames.hideInactive]: hideInactive }
            )}
            {...onClickIsSelect ? { onClick, onClickBefore } : {}}
            ref={ref}
            color={color}
            ButtonBaseContentComponent={() => {
                return (
                    <>
                        {itemProps.showAvatars && author?._id ?
                            <div className={_classNames.menuItemAvatar}>
                                <Avatar
                                    CustomAvatarContainerComponent={React.Fragment}
                                    user={author}
                                    variant={'text'}
                                    size={size}
                                    href={onClickIsSelect ? null : appContext.routes.userRoute + '/' + author._id}
                                    TypographyProps={{
                                        variant: 'menu-large'
                                    }}
                                    fullWidth={false}
                                    disabled={onClickIsSelect && disabled ? disabled : false}
                                    {...AvatarProps}
                                />
                            </div>
                            : null
                        }
                        <MenuItemComponent
                            TypographyComponent={(props) => {
                                return (
                                    <>
                                        {!onClickIsSelect && href && !preventHref ?
                                            <div
                                                className={_classNames.menuItemATag}
                                                onClick={onClick}
                                            /> : null
                                        }
                                        <Typography {...props} />
                                    </>
                                )
                            }}
                            label={label}
                            labelEnableWrap={true}
                            secondaryText={
                                <>
                                    {
                                        [...(itemProps.showSubtitles && secondaryText) ?
                                            [secondaryText] : [],
                                            ...(itemProps.status) ? [itemProps.status] : []
                                        ].join(', ')
                                    }
                                </>
                            }
                            className={clsx(
                                _classNames.menuItemItem
                            )}
                            enableBackground={false}
                            disableElevation={true}
                            size={size}
                            disabled={onClickIsSelect && disabled ? disabled : false}
                            {...!itemProps.showThumbnails ?
                                {
                                    startIcon: null
                                }
                                : {
                                    StartIconContainerComponent: ({ className }) => {
                                        return (
                                            <div className={className}>
                                                <Image
                                                    className={_classNames.menuItemThumb}
                                                    src={thumb}
                                                    width={'100%'}
                                                    height={'100%'}
                                                />
                                            </div>
                                        )
                                    },
                                    startIcon: <></>
                                }
                            }
                            color={color}
                            variant={'contained'}
                            Component={!onClickIsSelect && href ? 'a' : 'div'}
                            href={onClickIsSelect || preventHref ? null : href}
                            {...rest}
                        />
                        {
                            (!itemProps.disableTable && itemProps.tableData && Object.keys(itemProps.tableData).length) ?
                                <div className={_classNames.menuItemTable}>
                                    <Table
                                        LabelBoxProps={{
                                            inline: true
                                        }}
                                        show={'list'}
                                        tableData={itemProps.tableData}
                                        {...itemProps.tableProps ? itemProps.tableProps : itemProps.tableProps}
                                        appContext={appContext}
                                    />
                                </div>
                                : null
                        }
                        <div className={_classNames.menuItemMenuContainer}>
                            {menu?.length && !onClickIsSelect ?
                                <div className={_classNames.menuItemMenu}>
                                    <MenuContext.Provider value={menuContext}>
                                        <Menu
                                            menu={[...menu.map((m) => ({
                                                ...m, ...m.featured ? {
                                                    featuredMenuItemProps: {
                                                        size,
                                                        variant: 'contained',
                                                        Component: MenuItemComponent,
                                                        disableElevation: false,
                                                        className: _classNames.menuItemFeaturedMenuItem,
                                                        ...m.featuredMenuItemProps ? m.featuredMenuItemProps : {}
                                                    }
                                                } : {}
                                            }))]}
                                            PopoverProps={{
                                                AnchorComponent: MenuItemComponent,
                                                AnchorComponentProps: {
                                                    icon: <VerticalMoreIcon />,
                                                    size,
                                                    variant: 'contained',
                                                    disableElevation: false,
                                                    className: _classNames.menuItemFeaturedMenuItem
                                                }
                                            }}
                                            MenuItemProps={{
                                                size
                                            }}
                                        />
                                    </MenuContext.Provider>
                                </div>
                                : null
                            }
                            {selectable ?
                                <MenuItemComponent
                                    {...!onClickIsSelect ? { onClick, onClickBefore } : {}}
                                    className={clsx(
                                        _classNames.menuItemCheckbox,
                                        { [className]: !onClickIsSelect }
                                    )}
                                    enableBackground={false}
                                    disableElevation={true}
                                    disabled={disabled}
                                    size={size}
                                    color={color}
                                    variant={onClickIsSelect && disabled ? 'outlined' : 'contained'}
                                    Component={'button'}
                                    key={_active}
                                    effect={({ mounted }) => {
                                        buttonAction.current.mounted = mounted
                                    }}
                                    EndIconContainerComponent={(props) => {

                                        if (!selectable) {
                                            const { children, active, disabled, size, variant, color, ...rest } = props
                                            return (
                                                <div {...rest}>{children}</div>
                                            )
                                        }

                                        return (
                                            <CheckboxComponent
                                                {...props}
                                                {...{
                                                    disableElevation: false,
                                                    active: _active,
                                                    enableBackground: true,
                                                    color: color,
                                                    effect: ({ actions }) => {
                                                        buttonAction.current.actions = actions
                                                    },
                                                    _classNames
                                                }}
                                                ref={(e) => {
                                                    buttonRef.current = e
                                                    if (checkboxRef) {
                                                        if (typeof checkboxRef === 'function') {
                                                            checkboxRef(e)
                                                        } else if (checkboxRef && typeof checkboxRef === 'object') {
                                                            checkboxRef.current = e
                                                        }
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                    endIcon={<></>}
                                    fullWidth={false}
                                /> : null
                            }
                        </div>
                    </>
                )
            }}
        />
    )
})
