import { runPostTypesConfigSync } from '../../postTypes'

const labels = {
    notFoundButtonBack: 'Előző oldal',
    notFoundButtonHome: 'Főoldal',
    notFoundButtonLogin: 'Belépés',
    notFoundButtonSignup: 'Regisztráció',
    acceptCookiesDetailsLabel: 'Részletek',
    acceptCookiesLabel: 'Elfogadás',
    acceptCookiesClose: 'Bezár',
    selectAnOperationLabel: 'Művelet kiválasztása',
    searchLabel: 'Keresés',
    themeControlsMode: 'Szín séma',
    themeControlsDarkMode: 'Sötét',
    themeControlsLightMode: 'Világos',
    themeControlsAutoMode: 'Alapértelmezett',
    themeControlsAutoModeSec: 'Az eszköz beállítása szerint',
    themeControlsContrast: 'Kontraszt',
    themeControlsHighContrast: 'Kontrasztosabb',
    leaveWarningCancel: 'Mégsem',
    leaveWarningSubmit: 'Mehet',
    mobileMenu: 'Menü',
    logoAreaLabel: 'Főoldal',
    newReleaseCancelText: 'Folytatom',
    newReleaseSubmitText: 'Újratöltés',
    selectAll: 'Összes kijelölése',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'labels' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default labels
