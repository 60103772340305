import wapplrClient from 'wapplr'
import wapplrReact from 'wapplr-react'
import wapplrGraphql from 'wapplr-graphql'
import wapplrAuthentication from 'wapplr-authentication'
import wapplrPostTypes from 'wapplr-posttypes'

import setContents, { contentsMiddleware } from '../common/setContents'

import { getConfig as getCommonConfig } from '../common/config'

import initUser from './postTypes/user'
import initImage from './postTypes/image'
import initPost from './postTypes/post'
import initPage from './postTypes/page'
import initBanner from './postTypes/banner'
import initEmail from './postTypes/email'

import initCategory from './postTypes/category'
import initProduct from './postTypes/product'
import initMethod from './postTypes/method'
import initOrder from './postTypes/order'
import initPayment from './postTypes/payment'
import initNotification from './postTypes/notification'

import initPwa from './pwa'

export function getConfig(p = {}) {

    const { config = {} } = p

    const clientConfig = config.client || {}
    const commonConfig = getCommonConfig(p).config

    const common = { ...commonConfig.common }
    const globals = { ...commonConfig.globals }

    const client = {
        ...clientConfig,
        disableUseDefaultMiddlewares: true,
        ...!globals.DEV ?
            {
                styles: {
                    disableClearMainStyle: true
                }
            } : {}
    }

    return {
        config: {
            ...config,
            common: common,
            client: client
        }
    }

}

export default async function createClient(p) {

    console.log('[MOBILFLOWER] If you notice an error, please report it here: dev@digby.hu')

    const { config } = getConfig(p)
    const wapp = p.wapp || wapplrClient({ ...p, config })

    initPwa({ wapp })

    wapp.pwaInit()

    wapplrPostTypes({ wapp })
    wapplrAuthentication({ wapp })
    wapplrGraphql({ wapp })
    wapplrReact({ wapp })
    setContents({ wapp })

    initUser({ wapp })
    initImage({ wapp })
    initPost({ wapp })
    initPage({ wapp })
    initBanner({ wapp })
    initEmail({ wapp })

    initCategory({ wapp })
    initProduct({ wapp })
    initMethod({ wapp })
    initOrder({ wapp })
    initPayment({ wapp })
    initNotification({ wapp })

    wapp.requests.requestManager.fetch = async function(...attributes) {
        let r
        try {
            r = await window.fetch(...attributes)
        } catch (e) {
            if (!window.navigator.onLine) {
                console.log('[PWA] Lost connection')
                if (wapp.client.offlineHandler) {
                    wapp.client.offlineHandler()
                }
            }
        }
        if (r?.status === 404) {
            if (wapp.client.handleNotFoundResponse) {
                await wapp.client.handleNotFoundResponse({ response: r })
            }
        }
        return r
    }

    return wapp
}

export async function createMiddleware() {
    return [
        async function wapplrComMiddleware(req, res, next) {
            await contentsMiddleware(req, res)
            res.wapplrReactEndType = 'pipeWaitForAll'
            next()
        }
    ]

}

const defaultConfig = {
    config: {
        globals: {
            DEV: (typeof DEV !== 'undefined') ? DEV : undefined,
            WAPP: (typeof WAPP !== 'undefined') ? WAPP : undefined,
            RUN: (typeof RUN !== 'undefined') ? RUN : undefined,
            TYPE: (typeof TYPE !== 'undefined') ? TYPE : undefined,
            ROOT: (typeof ROOT !== 'undefined') ? ROOT : '/',
            NAME: (typeof NAME !== 'undefined') ? NAME : undefined
        }
    }
}

export async function run(p = defaultConfig) {

    if (p?.config?.globals && !p.config.globals.RUN) {
        p.config.globals.RUN = p.config?.globals.NAME || 'mobilflower-web'
    }

    const { config } = getConfig(p)
    const wapp = await createClient({ ...p, config })
    const globals = wapp.globals
    const { DEV } = globals

    const app = wapp.client.app

    app.use(wapp.client.middlewares.wapp)

    app.use(await createMiddleware({ wapp, ...p }))

    app.use([
        ...Object.keys(wapp.client.middlewares).map(function(key) {
            return (key === 'wapp') ? function next(req, res, next) {
                return next()
            } : wapp.client.middlewares[key]
        })
    ])

    wapp.client.listen()

    if (typeof DEV !== 'undefined' && DEV && module.hot) {
        app.hot = module.hot
        module.hot.accept()
    }

    return wapp
}

if (typeof RUN !== 'undefined' && RUN === 'mobilflower-web') {
    // noinspection JSIgnoredPromiseFromCall
    run()
}
