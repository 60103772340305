import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import XLSX from 'xlsx'

import clsx from 'clsx'

import { copyObject } from 'wapplr/dist/common/utils'
import { WappContext, withWapp } from 'wapplr-react/dist/common/Wapp'
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'

import Button from 'mobilflower-ui/dist/common/src/components/Button'
import Text from 'mobilflower-ui/dist/common/src/components/Input/Text'
import OpenInNewIcon from 'mobilflower-ui/dist/common/src/svg/OpenInNew'
import CircularProgress from 'mobilflower-ui/dist/common/src/svg/CircularProgress'

import CheckBoxOutlined from 'mobilflower-ui/dist/common/src/svg/CheckBoxIcon'
import CheckBoxOutlineBlankOutlined from 'mobilflower-ui/dist/common/src/svg/CheckBoxBlankIcon'

import PostContext from '../../../components/Post/context'
import AppContext from '../../../components/App/context'

import { postTypesConfig, runPostTypesConfigSync } from '../../index'

import capitalize from '../../../utils/capitalize'
import Dialog from '../../../components/Dialog'

import style from './style.css'

function Table({ children, ...props }) {
    return (
        <table
            {...props}
            className={clsx(style.table, props.className)}
        >
            {children}
        </table>
    )
}

function TableRow({ children, ...props }) {
    return (
        <tr
            {...props}
            className={clsx(style.tableRow, props.className)}
        >
            {children}
        </tr>
    )
}

function TableCell({ children, ...props }) {
    return (
        <td
            {...props}
            className={clsx(style.tableCell, props.className)}
        >
            <div className={style.flex}>
                {children}
            </div>
        </td>
    )
}

function TableHead({ children, ...props }) {
    return (
        <thead
            {...props}
            className={clsx(style.tableHead, props.className)}
        >
        {children}
        </thead>
    )
}

function TableBody({ children, ...props }) {
    return (
        <tbody
            {...props}
            className={clsx(style.tableBody, props.className)}
        >
        {children}
        </tbody>
    )
}

function parseExcel({ file, onLoad, onError }) {

    const reader = new FileReader()

    reader.onload = function(e) {
        let data = e.target.result
        if (data) {
            data = data.replace(/;0.\d+;/gm, (str) => str.replace(',', '.'))
        }
        const workbook = XLSX.read(data, { type: 'binary' })
        onLoad({ workbook })
    }

    reader.onerror = function(e) {
        onError(e)
    }

    reader.readAsBinaryString(file)
}

function SimpleText(props) {

    const { className, helperText = '', error, disabled } = props

    const value = useRef(props.value)

    function onChange(e) {
        e.preventDefault()
        const v = e.target.value
        if (v !== value.current) {
            value.current = v
        }
    }

    function onBlur(e) {
        e.preventDefault()
        onChange(e)
        if (props.onChange) {
            props.onChange(value.current)
        }
    }

    return (
        <Text
            key={value.current + '-' + (helperText || '-') + '-' + (error ? '-error' : '-no-error')}
            className={clsx(
                style.inputText,
                {
                    [style[className]]: style[className],
                    [style.withoutHelperText]: error || !helperText,
                    [style[error]]: error
                }
            )}
            HelperTextProps={{
                className: style.helperText
            }}
            ErrorMessageTypographyProps={{
                className: style.helperText
            }}
            InputProps={{
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                    }
                }
            }}
            helperText={!error ? helperText : null}
            variant={'outlined'}
            label={''}
            size={'small'}
            value={value.current}
            {...error ? {
                errorMessage: helperText,
                error: true
            } : {}}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
        />
    )
}

function Editable(props) {

    const {
        className, helperText = '', error, disabled, ValueComponent = ({ children, ...rest }) => {
            return <div {...rest}>{children}</div>
        }
    } = props

    const [value, setValue] = useState(props.value)
    const [edit, setEdit] = useState(false)

    function onClick(e) {
        if (disabled) {
            return
        }
        e.preventDefault()
        if (!edit) {
            setEdit(true)
        }
    }

    function onChange(e) {
        e.preventDefault()
        const v = e.target.value
        if (v !== value) {
            setValue(v)
        }
    }

    function onBlur(e) {
        e.preventDefault()
        onChange(e)
        if (edit) {
            setEdit(false)
        }
        if (props.onChange) {
            props.onChange(e.target.value)
        }
    }

    return (
        <>
            {(edit) ?
                <>
                    <Text
                        className={clsx(
                            style.inputText,
                            {
                                [style[className]]: style[className],
                                [style[error]]: error
                            }
                        )}
                        HelperTextProps={{
                            className: style.helperText
                        }}
                        InputProps={{
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            },
                            onFocus: (e) => {
                                e.target.setSelectionRange(e.target.value.length, e.target.value.length)
                            }
                        }}
                        variant={'outlined'}
                        label={''}
                        size={'small'}
                        value={value}
                        error={error}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                    />
                    {(helperText) ?
                        <div
                            className={clsx(
                                style.helperText,
                                {
                                    [style.error]: error
                                }
                            )}
                            onClick={onClick}
                        >
                            {helperText}
                        </div> : null
                    }
                </>
                :
                <>
                    <ValueComponent
                        className={clsx(
                            style.valueText,
                            {
                                [style[className]]: style[className],
                                [style.error]: error
                            }
                        )}
                        onClick={onClick}
                    >
                        <div>
                            {value}
                        </div>
                    </ValueComponent>
                    {(helperText) ?
                        <div
                            className={clsx(
                                style.helperText,
                                {
                                    [style.error]: error
                                }
                            )}
                            onClick={onClick}
                        >
                            {helperText}
                        </div> : null
                    }
                </>
            }
        </>
    )
}

function Progress(props) {

    const [show, setShow] = useState()

    useEffect(() => {
        if (props.effect) {
            props.effect({ actions: { setShow } })
        }
        return function() {
            if (props.effect) {
                props.effect({ actions: { setShow: () => null } })
            }
        }
    }, [])

    return (
        <>
            {(show) ?
                <div className={style.progress}>
                    <div>
                        <div>
                            <CircularProgress size={32} thickness={2.4} />
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    )
}

function Import() {

    const appContext = useContext(AppContext)
    const postContext = useContext(PostContext)
    const { name, user } = postContext
    const N = capitalize(name)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const context = useContext(WappContext)
    const { wapp } = context
    const utils = getUtils(context)

    const { storage } = appContext

    wapp.styles.use(style)

    const NewFile = useMemo(() => postTypesConfig['file'] && postTypesConfig['file'].getPages ? postTypesConfig['file'].getPages().new : null, [])

    const [file, setFile] = useState(storage()[name + 'ImportFile'] || null)
    const [rows, setRows] = useState(storage()[name + 'ImportRows'] || [])
    const [posts, setPosts] = useState([])
    const [autoRun, setAutoRun] = useState(false)

    const colNumbers = {
        ean: 10,
        title: 4,
        piecesInThePackage: 1,
        piecesInStock: 2,
        pot: 6,
        heightCm: 7,
        price1: 11,
        cover: 14
    }

    const eanPattern = /^(\d{13})?$/
    const filePostTypeName = 'file'
    const filePostType = wapp.getTargetObject().postTypes.findPostType({ name: filePostTypeName })
    const fileStatusManager = filePostType.statusManager

    async function rowValidation({ values, errors = [] }) {

        async function getImage(url) {
            let response = await fetch(appContext.routes[name + 'Route'] + '/api/getexternalimage', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url }),
                credentials: 'same-origin'
            })
            if (response && response.json) {
                response = response.json()
            }
            return response
        }

        async function upload(e, args) {

            const filesArray = Array.from(e.target.files || [])
            const filesLength = filesArray.length
            const filePropertyName = 'upload'
            const multiple = false

            if (!filesLength) {
                return
            }

            const requestProps = {
                requestName: filePostTypeName + 'New',
                args: {
                    ...args,
                    records: [
                        ...(args.records) ? args.records : []
                    ],
                    [filePropertyName]: (multiple) ? (filesLength) ? filesArray.map(() => null) : [] : null
                },
                multipart: true,
                callbackMultipartFormData: function({ formData }) {
                    const map = JSON.stringify((filesLength) ? filesArray.reduce((o, file, i) => {
                        const t = (multiple) ? 'variables.' + filePropertyName + '.' + (i) : 'variables.' + filePropertyName
                        o[(i)] = [t]
                        return o
                    }, {}) : {})
                    formData.append('map', map)
                    if (filesLength) {
                        filesArray.forEach((file, i) => {
                            formData.append((i).toString(), file, file.title)
                        })
                    }
                },
                formatBeforeRequest: function(url, options) {
                    delete options.headers['Content-Type']
                    return [url, options]
                },
                fetch: function(url, options = {}) {
                    return new Promise((response, reject) => {
                        let xhr = new XMLHttpRequest()
                        const { onProgress, ...restOptions } = options
                        xhr.open(restOptions.method || 'get', url)
                        for (let k in restOptions.headers || {}) {
                            xhr.setRequestHeader(k, restOptions.headers[k])
                        }
                        xhr.responseType = 'json'
                        xhr.onload = () => response(xhr.response)
                        xhr.onerror = reject
                        if (xhr.upload && onProgress) {
                            xhr.upload.onprogress = onProgress
                        }
                        xhr.send(options.body)
                    })
                }
            }

            const response = await utils.sendRequest(requestProps)

            let foundSuccess = -1
            let newSuccessFiles = []

            if (response.records && response.records.length) {
                const errors = (response.error?.errors) ? response.error.errors : (response.errors) ? response.errors : (response.error) ? [response.error] : null
                filesArray.forEach((file, i) => {
                    const thereIsError = (errors?.length) ? errors.find((error) => {
                        return typeof error.path == 'string' && error.path && Number(error.path) === i
                    }) : false
                    let r = { title: file.name }
                    if (!thereIsError) {
                        foundSuccess = foundSuccess + 1
                        if (response.records[foundSuccess]) {
                            r = { ...r, ...response.records[foundSuccess] }
                            newSuccessFiles.push(r)
                        }
                    }
                })
            }

            if ((response && response.error) || (response && response.errors)) {
                const message = (response.error?.message) || (response.errors && response.errors[0] && response.errors[0].message) || appContext.messages['new' + Ns + 'ClientErrorMessage']
                const errors = response.error?.errors || response.errors || [response.error]
                return {
                    error: {
                        message,
                        errors
                    }
                }
            } else if (response) {
                return newSuccessFiles[0]
            }
        }

        function createError(valueName, message) {
            return {
                message: message || appContext.messages[name + 'ImportXlsParseError']({ rowNumber: colNumbers[valueName] + 1 }),
                path: valueName,
                value: values[valueName]
            }
        }

        let piecesInThePackage = values.piecesInThePackage
        if (!isNaN(Number(piecesInThePackage))) {
            piecesInThePackage = Number(piecesInThePackage)
        }
        if (piecesInThePackage > 0) {
            values.piecesInThePackage = piecesInThePackage
        } else {
            errors.push(createError('piecesInThePackage'))
        }

        let piecesInStock = values.piecesInStock
        if (!isNaN(Number(piecesInStock))) {
            piecesInStock = Number(piecesInStock)
        }
        if (piecesInStock > 0) {
            values.piecesInStock = piecesInStock
        } else {
            errors.push(createError('piecesInStock'))
        }

        let title = values.title
        if (typeof title === 'string' && title) {
        } else {
            errors.push(createError('title'))
        }

        let ean = values.ean
        if (typeof ean == 'number' && ean) {
            ean = ean.toString()
        }
        if (typeof ean == 'string' && ean.match(eanPattern)) {
            values.ean = ean
        } else {
            errors.push(createError('ean'))
        }

        let pot = values.pot
        if (typeof pot === 'string' && pot) {
            pot = pot.replace(/,/g, '.')
            pot = pot.replace(/ /g, '')
        }
        if (!isNaN(Number(pot))) {
            pot = Number(pot)
        }
        if (pot > 0) {
            values.pot = pot
        } else {
            errors.push(createError('pot'))
        }

        let heightCm = values.heightCm
        if (typeof heightCm === 'string' && heightCm) {
            heightCm = heightCm.replace(/,/g, '.')
            heightCm = heightCm.replace(/ /g, '')
        }
        if (!isNaN(Number(heightCm))) {
            heightCm = Number(heightCm)
        }
        if (heightCm > 0) {
            values.heightCm = heightCm
        } else {
            errors.push(createError('heightCm'))
        }

        let price = values.price1
        if (typeof price === 'string' && price) {
            price = price.replace(/,/g, '.')
            price = price.replace(/ /g, '')
        }
        if (!isNaN(Number(price))) {
            price = Number(price)
        }
        if (price > 0) {
            values.price1 = price
        } else {
            errors.push(createError('price1'))
        }

        let cover = values.cover

        if (values._imagePost && values._imagePost.checksum !== cover) {
            values._imagePost = null
        }
        if (!cover) {
            cover = ''
        }
        if (cover) {
            let response =
                (values._imagePost && values._imagePost?.checksum !== cover) ?
                    { items: [values._imagePost] } :
                    await utils.sendRequest({
                        requestName: filePostTypeName + 'FindMany',
                        args: {
                            filter: {
                                checksum: cover,
                                _author: (user?._id) ? user._id : user,
                                _operators: {
                                    _status: { gt: fileStatusManager.getMinStatus() - 1 }
                                }
                            }
                        }
                    })

            if (typeof response[filePostTypeName + 'FindMany'] !== 'undefined') {
                response = response[filePostTypeName + 'FindMany']
            }
            if (response.items?.length) {
                values._imagePost = response.items[0]
            } else {
                try {
                    const data = await getImage(cover)
                    if (data?.status === 200 && data?.base64) {
                        const r = await fetch(data?.base64)
                        const blob = await r.blob()
                        let title = cover.substring(cover.lastIndexOf('/') + 1)
                        if (!title) {
                            title = (typeof values.title === 'string') ? values.title : 'cover'
                        }
                        blob.title = title
                        const uploadResponse = await upload({ target: { files: [blob] } }, { records: [{ checksum: cover }] })
                        if (uploadResponse?._id && uploadResponse?.checksum === cover) {
                            values._imagePost = uploadResponse
                        } else {
                            errors.push(createError('cover', uploadResponse?.error?.message))
                        }
                    } else {
                        errors.push(createError('cover', appContext.messages[name + 'ImportImageCouldNotOpenError']))
                    }
                } catch (e) {
                    errors.push(createError('cover', e.message))
                }
            }
        }

        return {
            ...(errors.length) ? {
                error: {
                    message: (errors.length) ? errors[0].message : 'Error',
                    errors
                }
            } : {}
        }

    }

    async function getRows(file) {
        if (file) {

            const { workbook } = file
            const sheetName = workbook.SheetNames[0]
            const ws = workbook.Sheets[sheetName]
            const rows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])

            const r = await Promise.all(rows.map(async (row) => {

                    const rN = row.__rowNum__

                    const keys = Object.keys(row)

                    const values = {}

                    const colName = '__EMPTY'

                    function findKey(rowNumber) {
                        const findKey = (rowNumber) ? colName + '_' + rowNumber.toString() : colName
                        return keys.find((key) => key === findKey)
                    }

                    function findValue(valueName) {
                        const key = findKey(colNumbers[valueName])
                        return key ? row[key] : null
                    }

                    Object.keys(colNumbers).forEach((valueName) => {
                        values[valueName] = findValue(valueName)
                        if (valueName === 'cover') {
                            const cN = colNumbers[valueName]
                            const cell = ws[XLSX.utils.encode_cell({ c: cN, r: rN })]
                            values[valueName] = cell?.l?.Target || ''
                        }
                    })

                    return {
                        ...await rowValidation({ values }),
                        values
                    }

                })
            )

            return (r?.length) ? r.filter(({ values }) => values.ean && values.ean.match(eanPattern)) : []

        }
        return []
    }

    async function getPosts(rows) {
        const posts = []
        if (Array.isArray(rows) && rows.length) {
            const res = await Promise.all(
                rows.map(async ({ values }) => {
                    if (values.ean) {
                        let response = await utils.sendRequest({
                            requestName: name + 'FindByEan',
                            args: { ean: values.ean }
                        })
                        if (typeof response[name + 'FindByEan'] !== 'undefined') {
                            response = response[name + 'FindByEan']
                        }
                        return response
                    }
                    return null
                })
            )
            posts.push(...res)
        }
        setPosts(posts)
        return posts
    }

    const onSuccess = async function(files) {
        const file = files[0]
        await progress.actions.setShow(true)
        const rows = await getRows(file)

        await setFile(file)

        if (file && !rows?.length) {
            await appContext.template.current?.actions?.setSnackMessage(appContext.messages[name + 'ImportFileError'], 6000, 'error')
        }

        await setRows(rows)

        if (file && rows?.length) {
            storage({ [name + 'ImportRows']: rows })
            storage({ [name + 'ImportFile']: {} })
            await getPosts(rows)
        }
        await progress.actions.setShow(false)
    }

    async function close() {
        storage({ [name + 'ImportRows']: [], [name + 'ImportFile']: null })
        await setRows([])
        await setPosts([])
        await setFile(null)
    }

    const newRequestFormData = utils.getGlobalState('res.graphql.mutation.' + name + 'New.formData')

    const propertyNames = Object.keys(colNumbers)

    const dialog = useRef()
    const dialogEffect = ({ actions }) => {
        dialog.actions = actions
    }

    const form = useRef()

    const progress = useRef()
    const progressEffect = ({ actions }) => {
        progress.actions = actions
    }

    async function onClick(e, post, rows, row, i, saved) {

        if (saved) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        if (dialog.actions) {

            function DialogContent() {

                const context = useContext(WappContext)

                const { wapp, req, res } = context

                //const ean = post?.ean || "";
                const route = appContext.routes[name + 'Route']
                const postId = post?._id

                const path = (route && postId) ? route + '/' + postId + '/edit' : route + '/new'

                const newContext = (path) ?
                    {
                        wapp,
                        req: {
                            user: req.user,
                            wappRequest: {
                                user: req.wappRequest.user,
                                query: {}
                            }
                        },
                        res: {
                            wappResponse: {
                                route: {
                                    requestPath: path,
                                    ...(postId) ? {
                                        params: {
                                            page: 'edit',
                                            _id: postId
                                        }
                                    } : { params: { page: 'new' } }
                                },
                                store: res.wappResponse.store,
                                status: res.wappResponse.status
                            }
                        }
                    } : context

                const PostTypesComponent = (path) ? runPostTypesConfigSync({
                    action: 'getComponent',
                    p: { context: newContext, appContext }
                }).filter((C) => !!(C))[0] : null

                const categoryMenuResponse = utils.getGlobalState('res.responses.categories')

                const { _imagePost, cover, ...restValues } = row.values

                const category = categoryMenuResponse?.items?.length && categoryMenuResponse.items.find((category) => category.slug === 'holland')

                const newPost = { ...(post) ? post : {}, ...restValues, categories: (category) ? [category] : [] }

                if (post) {
                    const currentStock = typeof post.piecesInStock == 'number' ? post.piecesInStock : 0
                    const value = typeof row.values.piecesInStock == 'number' ? row.values.piecesInStock : 0
                    newPost.piecesInStock = currentStock + value
                }

                if (cover && _imagePost && cover === _imagePost.checksum) {
                    newPost.cover = copyObject(_imagePost)
                    newPost.gallery = [copyObject(_imagePost)]
                }

                return (
                    <WappContext.Provider value={newContext}>
                        <div>
                            <PostTypesComponent
                                getInitialResponse={() => newPost}
                                getMenu={() => []}
                                pageProps={{
                                    initialValues: {
                                        ...(newPost) ? Object.keys(newPost).reduce((o, key) => {
                                            o['record.' + key] = newPost[key]
                                            return o
                                        }, {}) : {}
                                    },
                                    formProps: ({ formData }) => ({
                                        ref: (e) => {
                                            form.current = e
                                        },
                                        successMessage: null,
                                        formData: { ...formData, submit: { ...formData.submit, hidden: true } }
                                    }),
                                    requestProps: { redirect: null, go: 0 }
                                }}
                                layoutType={'article'}
                                PaperComponentProps={{ elevation: 0, style: { padding: 0 } }}
                            />
                        </div>
                    </WappContext.Provider>
                )
            }

            const isNotDeleted = post && post._status_isNotDeleted

            const onSubmit = async (e) => {
                e.preventDefault()
                if (form.current) {
                    if (form.current.progress || form.current.done) {
                        return null
                    }
                    const response = await form.current.onSubmit(e)
                    if ((response && response.error) || (response && response.errors)) {
                        if (autoRun) {
                            await progress.actions.setShow(false)
                        }
                        await dialog.actions.show()
                    } else if (response?.record._id) {

                        const newRows = JSON.parse(JSON.stringify(rows))
                        const newRow = JSON.parse(JSON.stringify(row))
                        newRow.saved = response.record
                        newRows[i] = { ...newRow }

                        await dialog.actions.close()
                        storage({ [name + 'ImportRows']: newRows })

                        if (autoRun) {
                            const saveAbleRow = newRows.find(({ values, error, saved }) => !error && !saved)
                            const fI = newRows.indexOf(saveAbleRow)
                            if (saveAbleRow && fI > -1) {
                                const foundPost = posts && posts.find((post) => post?.ean === saveAbleRow.values.ean)
                                await onClick(e, foundPost, newRows, saveAbleRow, fI, saveAbleRow.saved)
                            } else {
                                await setRows(newRows)
                                await progress.actions.setShow(false)
                            }
                        } else {
                            await setRows(newRows)
                        }

                    } else {
                        await appContext.template.current?.actions?.setSnackMessage(appContext.messages['save' + N + 'DefaultFail'], 6000, 'error')
                    }
                }
            }

            if (autoRun) {
                await progress.actions.setShow(true)
                await dialog.actions.hide()
            } else {
                await dialog.actions.show()
            }

            form.current = null

            await dialog.actions.open({
                dialogTitle: '',
                dialogContent: <>
                    <DialogContent />
                </>,
                cancelText: appContext.labels['cancel' + N + 'Text'],
                submitText: post?._id ? isNotDeleted ? appContext.labels[name + 'ImportEditLabel'] : appContext.labels[name + 'ImportRestoreLabel'] : appContext.labels['new' + N + 'SubmitLabel'],
                onCancel: async (e) => {
                    e.preventDefault()
                    await dialog.actions.close()
                },
                onSubmit: onSubmit,
                dialogContentClassName: style.dialogContent
            })

            if (autoRun) {
                await new Promise((resolve) => {
                    const int = setInterval(() => {
                        if (form.current) {
                            clearInterval(int)
                            resolve()
                        }
                    }, 10)
                })
                await onSubmit(e)
            }

        }
    }

    async function getPostsWithProgress() {
        await progress.actions.setShow(true)
        await getPosts(rows)
        await progress.actions.setShow(false)
    }

    useEffect(() => {
        if (file && rows?.length && !posts?.length) {
            getPostsWithProgress()
        }
    }, [])

    return (
        <div className={style.import}>
            <Progress effect={progressEffect} />
            {(!file || file && !rows?.length) ?
                <NewFile
                    request={async function({ filesArray }) {

                        const records = []
                        const errors = []

                        await new Promise((resolve, reject) => {
                            parseExcel({
                                file: filesArray[0],
                                onLoad: ({ workbook }) => {
                                    records.push({ workbook })
                                    resolve()
                                },
                                onError: (e) => {
                                    errors.push({
                                        message: e.message,
                                        path: '0'
                                    })
                                    reject()
                                }
                            })
                        })

                        return {
                            records,
                            ...(errors.length) ? { error: { message: errors[0].message, errors } } : {}
                        }
                    }}
                    name={name}
                    onSuccess={async ({ files, setOpenFiles }) => {
                        const newFiles = files && files.length && files.filter((file) => file && file.workbook)
                        if (newFiles.length) {
                            await new Promise((resolve) => setTimeout(resolve, 1000))
                            await setOpenFiles(false)
                            await onSuccess(newFiles)
                        }
                    }}
                    dropZone={false}
                    multiple={false}
                    accept={'application/vnd.ms-excel'}
                    label={appContext.labels[name + 'ImportSelectFileLabel']}
                    successOpenInNew={false}
                /> : null
            }
            <div className={style.buttonsContainer}>
                {(file && rows?.length) ?
                    <div className={style.closeContainer}>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            onClick={close}
                        >
                            {appContext.labels[name + 'ImportRestartLabel']}
                        </Button>
                    </div> : null
                }
                {(file && rows?.length) ?
                    <div className={style.autoRunContainer}>
                        <Button
                            onClick={() => setAutoRun(!autoRun)}
                            startIcon={(autoRun) ? <CheckBoxOutlined color={'secondary'} /> :
                                <CheckBoxOutlineBlankOutlined />}
                        >
                            {appContext.labels[name + 'ImportAutoRunLabel']}
                        </Button>
                    </div>
                    : null
                }
            </div>
            {
                (file && rows?.length) ?
                    <div className={style.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {propertyNames.map((propertyName) => {
                                        return (
                                            <TableCell key={propertyName} name={propertyName}>
                                                {newRequestFormData['record.' + propertyName].label}
                                            </TableCell>
                                        )
                                    })}
                                    {(posts.length) ?
                                        <TableCell key={'save'}>
                                            {appContext.labels['save' + N + 'SubmitLabel']}
                                        </TableCell>
                                        : null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(({ values, error, saved }, i) => {

                                    const foundPost = posts && posts.find((post) => post?.ean === values.ean)
                                    const isNotDeleted = foundPost?._status_isNotDeleted

                                    return (
                                        <TableRow
                                            key={i}
                                            className={clsx(
                                                style.tableRow,
                                                { [style.savedRow]: rows[i].saved }
                                            )}
                                        >
                                            {propertyNames.map((propertyName) => {

                                                const isError = !!(error?.errors && error?.errors.find(({ path }) => path === propertyName))
                                                let helperText = isError && error.errors.filter(({ path }) => path === propertyName).map(({ message }) => message).join(', ')

                                                if (!isError && propertyName === 'piecesInStock' && foundPost && !saved) {
                                                    const currentStock = typeof foundPost.piecesInStock == 'number' ? foundPost.piecesInStock : 0
                                                    const value = typeof values[propertyName] == 'number' ? values[propertyName] : 0
                                                    const newStock = currentStock + value
                                                    helperText = appContext.messages[name + 'HelperTextStock']({
                                                        current: currentStock,
                                                        newValue: newStock
                                                    })
                                                }

                                                if (!isError && propertyName === 'piecesInStock' && saved) {
                                                    const savedStock = saved.piecesInStock || 0
                                                    helperText = appContext.messages[name + 'HelperTextStockSaved']({ saved: savedStock })
                                                }

                                                return (
                                                    <TableCell key={propertyName} name={propertyName}>
                                                        {(propertyName === 'ean') ?
                                                            values[propertyName] :
                                                            propertyName === 'cover' ?
                                                                <Editable
                                                                    className={propertyName}
                                                                    value={values[propertyName]}
                                                                    error={isError}
                                                                    helperText={helperText}
                                                                    disabled={!!(saved)}
                                                                    onChange={async (value) => {
                                                                        if (values[propertyName] !== value) {
                                                                            values[propertyName] = value
                                                                            await progress.actions.setShow(true)
                                                                            rows[i] = {
                                                                                ...await rowValidation({ values }),
                                                                                values,
                                                                                saved
                                                                            }
                                                                            const newRows = [...rows]
                                                                            storage({ [name + 'ImportRows']: newRows })
                                                                            await setRows(newRows)
                                                                            await progress.actions.setShow(false)
                                                                        }
                                                                    }}
                                                                    ValueComponent={({ children, ...rest }) => {
                                                                        if (propertyName === 'cover' && values._imagePost) {
                                                                            return (
                                                                                <div
                                                                                    {...rest}
                                                                                    className={
                                                                                        clsx({
                                                                                                [rest.className]: rest.className
                                                                                            },
                                                                                            style.coverImage
                                                                                        )
                                                                                    }
                                                                                    style={{ backgroundImage: 'url(' + values._imagePost.thumb + ')' }}
                                                                                />
                                                                            )
                                                                        }
                                                                        return (
                                                                            <div {...rest}>
                                                                                {children}
                                                                            </div>
                                                                        )
                                                                    }}
                                                                /> :
                                                                <SimpleText
                                                                    className={propertyName}
                                                                    value={values[propertyName]}
                                                                    error={isError}
                                                                    helperText={helperText}
                                                                    disabled={!!(saved)}
                                                                    onChange={async (value) => {
                                                                        if (values[propertyName] !== value) {
                                                                            values[propertyName] = value
                                                                            await progress.actions.setShow(true)
                                                                            rows[i] = {
                                                                                ...await rowValidation({ values }),
                                                                                values,
                                                                                saved
                                                                            }
                                                                            const newRows = [...rows]
                                                                            storage({ [name + 'ImportRows']: newRows })
                                                                            await setRows(newRows)
                                                                            await progress.actions.setShow(false)
                                                                        }
                                                                    }}
                                                                />
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                            {(posts.length) ?
                                                <TableCell
                                                    className={style.saveCell}
                                                    key={'save'}
                                                >
                                                    <Button
                                                        variant={'contained'}
                                                        color={(!!(error?.errors?.length) || !!(saved)) ? 'secondary' : 'secondary'}
                                                        onClick={(e) => onClick(e, foundPost, rows, rows[i], i, saved)}
                                                        href={(saved) ? appContext.routes[name + 'Route'] + '/' + saved._id : null}
                                                        target={(saved) ? '_blank' : null}
                                                        disabled={!!(error?.errors?.length)}
                                                        startIcon={(saved) ? <OpenInNewIcon /> : null}
                                                    >
                                                        {(saved) ?
                                                            appContext.labels[name + 'ImportSavedAlreadyLabel'] :
                                                            (foundPost) ?
                                                                isNotDeleted ? appContext.labels[name + 'ImportEditLabel'] : appContext.labels[name + 'ImportRestoreLabel'] :
                                                                appContext.labels['new' + N + 'SubmitLabel']}
                                                    </Button>
                                                </TableCell>
                                                : null
                                            }
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    :
                    (file) ?
                        <div className={style.error}>
                            {appContext.messages[name + 'ImportFileError']}
                        </div>
                        : null
            }
            <Dialog
                effect={dialogEffect}
                hiddenClassName={'none'}
                ContentComponentProps={{
                    Component: 'div'
                }}
            />
        </div>
    )
}

export default withWapp(Import)
