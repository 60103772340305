import getUserPostTypeConfig from './user'
import getImagePostTypeConfig from './image'
import getPostPostTypeConfig from './post'
import getPagePostTypeConfig from './page'
import getBannerPostTypeConfig from './banner'
import getEmailPostTypeConfig from './email'

import getCategoryPostTypeConfig from './category'
import getProductPostTypeConfig from './product'
import getMethodPostTypeConfig from './method'
import getOrderPostTypeConfig from './order'
import getPaymentPostTypeConfig from './payment'
import getNotificationPostTypeConfig from './notification'

export const postTypesConfig = {
    user: getUserPostTypeConfig,
    file: getImagePostTypeConfig,
    post: getPostPostTypeConfig,
    page: getPagePostTypeConfig,
    banner: getBannerPostTypeConfig,
    email: getEmailPostTypeConfig,

    category: getCategoryPostTypeConfig,
    product: getProductPostTypeConfig,
    method: getMethodPostTypeConfig,
    order: getOrderPostTypeConfig,
    payment: getPaymentPostTypeConfig,
    notification: getNotificationPostTypeConfig
}

export function runPostTypesConfigSync({ action, rKey, p = {} }) {
    const config = postTypesConfig
    return Object.keys(config).map(function(key) {
        const postTypeConfig = config[key]
        if (postTypeConfig[action]) {
            const r = postTypeConfig[action](p)
            return (rKey) ? r[rKey] : r
        }
        return null
    })
}

export async function runPostTypesConfig({ action, rKey, p = {} }) {
    const config = postTypesConfig
    return await Promise.all(
        Object.keys(config).map(async function(key) {
            const postType = config[key]
            if (postType[action]) {
                const r = await postType[action](p)
                return (rKey) ? r[rKey] : r
            }
            return null
        })
    )
}
