import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'product'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Új mentése',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Termékek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',

            ['moreFromMy' + Ns + 'Title']: 'További termékeim',
            ['moreFromUser' + Ns + 'Title']: 'További termékek',

            [n + 'SearchTextPlaceholder']: 'Keresés',
            [n + 'SearchSubmitLabel']: 'Keresés',
            [n + 'RefreshSubmitLabel']: 'Találatok frissítése',

            [n + 'PageInfoPerPage']: ({ startItem, endItem, allItem, perPage, currentPage }) => {
                if (currentPage >= 2 && (endItem - startItem <= perPage)) {
                    return `${currentPage}. oldal - ${allItem} termék összesen`
                }
                if (allItem <= perPage || currentPage < 2) {
                    return `${allItem} termék összesen`
                }
                return `${startItem} - ${endItem}, ${allItem} termék összesen`
            },
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                } catch (e) {
                }
                return dateText
            },

            [ns + 'SortRelevance']: 'Relevancia',

            [n + 'TitleLabel']: 'Név',
            [n + 'ImportSelectFileLabel']: 'File kiválasztása',
            [n + 'ImportSavedAlreadyLabel']: 'Elmentve',
            [n + 'ImportRestartLabel']: 'Újrakezdés',
            [n + 'ImportAutoRunLabel']: 'Automatikusan mentse a következőt',
            [n + 'ImportEditLabel']: 'Módosítás',
            [n + 'ImportRestoreLabel']: 'Visszaállítás',
            ['empty' + Ns + 'Text']: 'Ürítés',
            ['emptyAll' + Ns + 'Text']: 'Ürítés',
            ['deleteAll' + Ns + 'Text']: 'Összes törlése',

            [n + 'OverviewProducts']: 'Termékek',
            [n + 'OverviewNew']: 'Új termék',
            [n + 'OverviewImportMenu']: 'Importálás',
            [n + 'OverviewEmptyAll']: 'Készlet ürítése',
            [n + 'OverviewDeleteAll']: 'Összes törlése'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Termékek',
            ['my' + Ns + 'Menu']: 'Termékeim',
            ['new' + N + 'Menu']: 'Új termék',
            [ns + 'Menu']: 'Termékek',
            ['deleted' + Ns + 'Menu']: 'Törölt termékek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt termékek',
            ['protection' + Ns + 'Menu']: 'Védett termékek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése',

            ['get' + N + 'CategoryAll']: ({ category }) => {
                return 'Összes ' + category.title
            },
            //[n + 'KonyvekCategoryAll']: 'Összes könyv',

            [ns + 'ImportMenu']: 'Importálás',
            [ns + 'EmptyAllMenu']: 'Készlet ürítése',
            [ns + 'DeleteAllMenu']: 'Összes törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új termék mentésre került',
            ['save' + N + 'SuccessMessage']: 'A termék mentésre került',
            ['delete' + N + 'Success']: 'A termék törölve lett',
            ['delete' + Ns + 'Success']: 'A termékek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a terméket?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a termékeket?',
            ['ban' + N + 'Success']: 'A termék véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a terméket véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A termék jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a terméket',
            ['protection' + N + 'Success']: 'A termék védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a terméket?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek termékek',
            [n + 'ImportXlsParseError']: () => 'Hibás érték',
            [n + 'ImportFileError']: 'A file egyetlen sora sem tartalmazott helyes EAN számot',
            ['save' + N + 'DefaultFail']: 'Hiba történt a bejegyzés mentése közben, próbálja újra',
            [n + 'HelperTextStock']: ({ current, newValue }) => 'Jelenlegi:' + current + ', új:' + newValue,
            [n + 'HelperTextStockSaved']: ({ saved }) => 'Mentett:' + saved,
            [n + 'ImportImageCouldNotOpenError']: 'A képet nem lehetett megnyitni',
            ['emptyStock' + Ns + 'Question']: 'Biztosan üríti a készletet a kijelölt termékeknél?',
            ['emptyStock' + Ns + 'Success']: 'A termékek ürítve lettek',

            ['emptyStockAll' + Ns + 'Question']: 'Biztosan üríti a teljes készletet?',
            ['emptyStockAll' + Ns + 'Success']: 'A termékek ürítve lettek',

            ['deleteAll' + Ns + 'Question']: 'Biztosan törli az összes terméket?',
            ['deleteAll' + Ns + 'Success']: 'A termékek törölve lettek'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Termék',
            [ns + 'Title']: 'Termékek',
            ['new' + N + 'Title']: 'Új termék',
            ['edit' + N + 'Title']: 'Termék szerkesztése',
            ['user' + Ns + 'Title']: 'Termékek',
            ['userDeleted' + Ns + 'Title']: 'Törölt termékek',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt termékek',
            ['my' + Ns + 'Title']: 'Termékeim',
            ['myDeleted' + Ns + 'Title']: 'Törölt termékeim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt termékeim',

            ['dialogDelete' + N + 'Title']: 'Termék törlése',
            ['dialogBan' + N + 'Title']: 'Termék törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Termék jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Termék védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Termék védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Termékek törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt termékek',
            ['deleted' + Ns + 'Title']: 'Törölt termékek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett termékek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Termékek kiválasztása',
            ['select' + N]: 'Termék kiválasztása',

            [ns + 'ImportTitle']: 'Termékek importálása',
            [ns + 'ImportSubtitle']: 'Válaszd ki az exceltáblát. Csak azokat a sorokat veszi figyelembe a program, amiben szerepel helyes EAN szám. Ahol piros mező van, ott az adat nem megfelelő. Minden adat kötelező, kivéve a borítókép: ha a borítókép nincs megadva, akkor az nem módosul meglévő terméknél, vagy üres marad ha új terméket készítesz. Ellenőrízd le a beolvasott értékeket és nyomj az \'Új mentése\' vagy a \'Módosítás\' vagy \'Visszaállítás\' gombra. A mentési képernyő egy felugró ablakban jelenik meg, már az excelből importált adatokkal. A \'Darab készlet\' összeadódik, a többi mező felülíródik (a borítókép csak akkor ha megvan adva). Ebben az ablakban szerkesztheted a további adatokat is mentés előtt. Kattints az \'Új mentése\' vagy a \'Módosítás\' vagy \'Visszaállítás\' gombra a felugró ablak jobb alsó sarkában. A mentés során ellenőrzésre kerülnek az adatok, ha hibás adat van pirossal jelölődik, ha sikerült a mentés bezáródik az ablak, és zölddel jelölődik a mentett sor. Ha az \'Automatikusan mentse a következőt\' jelölőégyzet be van kapcsolva, akkor a folyamat magától végig fut az első Mentés után. Csak akkor áll le, ha hibát észlel. A böngésző tárjában elmentődik ez a táblázat, így félbehagyhatod a munkát, amikor visszatérsz újra látható lesz a táblázat. Ha egy másik excelt szeretnél feltölteni kattints az \'Újrakezdés\' gombra.',

            ['dialog' + Ns + 'EmptyStockTitle']: 'Készlet ürítés',
            ['dialog' + Ns + 'EmptyStockAllTitle']: 'Teljes készület ürítése',
            ['dialog' + Ns + 'DeleteAllTitle']: 'Összes termék törlése'
        }
    }
}
